import { HttpClient } from '../../core/common/services/HttpClient'
import FileIconService from '../../core/fileIcons/services/FileIconService';
import FileHelper from '../../core/fileIcons/services/FileHelper';

const HomeworkService = {
  getHomeworkDetails: (schoolId: string, LinkId: string) => {
    const url = 'api/v1/homework/' + schoolId + '/' + LinkId
    return HttpClient().get(url).then((response: any) => {
      let result = response.data;
      result.studentname = result.displayForename + ' ' + result.displaySurname
      result.attachments = HomeworkService.getAttachmentList(result.attachments, schoolId)
      result.studentAttachments = HomeworkService.getAttachmentList(result.studentAttachments, schoolId)
      result.daycnt = HomeworkService.dateDifferenceIndays(new Date(), new Date(result.dueDate))
      return result
    })
  },

  downloadAttachment: (schoolGuid: string, attachmentId: string) => {
    const url = `api/v1/homework/attachment/${schoolGuid}/${attachmentId}`
    return HttpClient().get(url).then((response: any) => {
      try {
        window.location = response.data;
      } catch (exception) {
        console.log(exception);
      }
    })
  },

  dateDifferenceIndays: (dt1: any, dt2: any) => {
    return Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) / (1000 * 60 * 60 * 24));
  },
  upload: (formData: any, config: any, homework: any) => {
    const url = `api/v1/homework/attachment/${homework.schoolGuid}/${homework.id}`
    return HttpClient(0).post(url, formData, config);
  },
  getAllowedAttachmentExtensions: () => {
    const address = '/api/v1/Homework/attachment';
    const sessionKey = 'AttachmentExtensions';
    let cachedResult = sessionStorage.getItem(sessionKey);
    if (cachedResult) {
      return JSON.parse(cachedResult);
    }
    return HttpClient().get(address).then((response) => {
      sessionStorage.setItem(sessionKey, JSON.stringify(response.data));
      return response.data;
    })
  },
  isFileExtensionValid: async (fileExtension: any) => {
    var validFileExtensions = await HomeworkService.getAllowedAttachmentExtensions()
    validFileExtensions = validFileExtensions.map((x: any) => x.toLowerCase())
    let isvalid = false;
    let fileTypePresent = validFileExtensions.filter((x: any) => x === fileExtension.toLowerCase());
    if (fileTypePresent.length > 0) {
      isvalid = true;
    }
    return isvalid;
  },

  getAttachmentList: (attachmentList: any, schoolId: any) => {
    let fileService = new FileIconService();

    return attachmentList.map((attachment: any) => {
      return {
        id: attachment.id,
        fileName: attachment.fileName,
        description: attachment.description,
        title: attachment.title,
        icon: fileService.getIcon(FileHelper.getFileExtensionsFromFileName(attachment.fileName)),
        fileSize: FileHelper.getHumanReadableFileSize(attachment.fileSize),
        schoolId: schoolId
      }
    })
  },

  submitHomework: (schoolGuid: string, request: any) => {
    const url = `api/v1/homework/${schoolGuid}`
    return HttpClient().post(url, JSON.stringify(request)).then((response: any) => {
      try {
        return response;
      } catch (exception) {
        console.log(exception);
        return exception;
      }
    })
  }
}

export default HomeworkService