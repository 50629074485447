import React, { useState, useEffect } from 'react'
import Attachments from '../../../core/attachment/components/Attachments/Attachments'
import './HomeworkSubmission.css';
import { AttachmentStore } from '../../../core/attachment/store/AttachmentStore';
import { Attachment } from '../../../core/attachment/modal/Attachment';
import FieldValidationError from '../../../core/validations/components/FieldValidationError/FieldValidationError';

import HomeworkService from '../../services/HomeworkService';
import { IonIcon, IonModal, IonLabel, IonLoading } from '@ionic/react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { css } from 'glamor';
import { appInsightsService } from '../../../core/common/services/AppInsightsService';
import { send } from 'ionicons/icons'

type Props = {
    homework: any;
    onReload: (homework: any) => void;
}

const HomeworkSubmission: React.FC<Props> = ({ homework, onReload }) => {
  const initaliValue: Attachment[] = [];
  const [attachments, setAttachments] = useState(initaliValue);
  const [isUploading, setIsUploading] = useState(false); // This will be used in submit button
  const [showModal, setShowModal] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  const submitClick = async () => {
    setShowModal(true);
  }
  const cancelClick = async () => {
    setShowModal(false);
  }
  const submitAndConfirmClick = async () => {
    setShowLoading(true);
    let reduxState = AttachmentStore.getState();
    var request = {
      schoolGuid: homework.schoolGuid,
      homeworkStudentLinkId: homework.id,
      studentAttachments: reduxState.attachments
    };

    HomeworkService.submitHomework(homework.schoolGuid, request)
      .then(() => {
        appInsightsService.trackEvent('Homework submitted for school: ' + homework.schoolGuid + ' and for HomeworkLinkId : ' + homework.id);
        setShowModal(false);
        setShowLoading(false);
        setIsSubmit(true);
        HomeworkService.getHomeworkDetails(homework.schoolGuid, homework.id).then((reponse: any) => {
          onReload(reponse);
        })
      })
      .catch((error) => {
        setShowModal(false);
        setShowLoading(false);
        toast('Error occurred while submitting homework. ' + error, {
          hideProgressBar: true,
          className: css({
            background: '#B22222 !important'
          }),
          bodyClassName: 'toast-body',
          autoClose: 5000,
          closeButton: false
        });
      });
  }

  const onUploadingAttachmentsStatusChange = (isUploading: boolean) => {
    setIsUploading(isUploading);
  }

  const saveAttachmentStateToRedux = (attachmentsUploaded: Attachment[]) => {
    AttachmentStore.dispatch({
      type: 'GET_Attachments',
      attachments: attachmentsUploaded
    })
  }

  const saveValidationStateToRedux = (validationMessage: any) => {
    AttachmentStore.dispatch({
      type: 'GET_Validations',
      validations: validationMessage
    })
  }

  const onAttachmentValidationErrorsChange = (validationErrors: any) => {
    const fieldId = 'homework-attachments'
    if (validationErrors.length === 0) {
      saveValidationStateToRedux([]);
    }

    var mappedErrors = validationErrors.map((message: any) => ({ id: fieldId, errorMessage: message }))
    let reduxState = AttachmentStore.getState();
    validationErrors = reduxState.validations;
    let errors: any = [];
    errors = [...mappedErrors, ...validationErrors];
    saveValidationStateToRedux(errors);
  }

  const onAttachmentsChange = (attachment: Attachment[]) => {
    const newAttachments = [...attachment];
    setAttachments(newAttachments);
    saveAttachmentStateToRedux(newAttachments);
  }

  useEffect(() => {
    setAttachments(attachments);
  }, [attachments])

  if (showLoading) {
    return <IonLoading
      isOpen={showLoading}
      onDidDismiss={() =>
        setShowModal(false)}
      message={'Loading ...'}
      duration={5000}
    />
  }

  const getValidationMessages = (id: any) => {
    let reduxState = AttachmentStore.getState();
    let validationErrors = reduxState.validations;
    let errors = validationErrors.filter((x: any) => { return x.id === id });
    if (errors.length) {
      return errors.map((error: any) => {
        return (
          <FieldValidationError key={error.id} error={error ? error.errorMessage : null} />)
      })
    }
    return <div className="validation-field"></div>;
  }

  return (
    <div>
      <span className="submit-title">
        <IonLabel>
                    Submit Homework for {homework.studentname}
        </IonLabel>
      </span>
      <div className="upload-section">
        {getValidationMessages('homework-attachments')}
        <Attachments
          onChange={onAttachmentsChange}
          onValidationErrorsChange={onAttachmentValidationErrorsChange}
          onUploadingStatusChange={onUploadingAttachmentsStatusChange}
          homework={homework}
        />
      </div>

      <div style={{ display: (isSubmit ? 'none' : 'block') }} className="submit-button">
        <div>
          <button disabled={attachments.length === 0 || isUploading} onClick={() => submitClick()} className="buttons-outlined-with-icon-enable">
            <IonIcon className="submiticon" icon={send} mode="md" />
            <span className="submit-button-text">SUBMIT FOR MARKING</span></button>
        </div>
      </div>

      <IonModal backdropDismiss={false} cssClass="my-custom-modal-css" isOpen={showModal}>
        <div className="container-color">
          <div className="submit-popup-body">
                        Please confirm that you are submitting {homework.subject} homework for {homework.displayForename} {homework.displaySurname}
          </div>
          <div className="dialogs-elements-footer">
            <div className="popup-header-divider"></div>
            <button className="dialog-cancel-button" onClick={() => cancelClick()}>
                            CANCEL</button>
            <button className="dialog-submit-button" onClick={() => submitAndConfirmClick()}>
                            CONFIRM AND SUBMIT</button>
          </div>
        </div>
      </IonModal>
    </div>
  )
}

export default HomeworkSubmission
