
const url = '/assets/fileIcons/';
class FileIconService {
    getIcon = function (extension: any) {
      switch (extension.toLowerCase()) {
        case 'zip':
          return url + 'zip.svg';
        case 'rtf':
          return url + 'rtf.svg';
        case 'svg':
          return url + 'svg.svg';
        case 'ai':
          return url + 'ai.svg';
        case 'avi':
          return url + 'avi.svg';
        case 'mp4':
          return url + 'mp4.svg';
        case 'psd':
          return url + 'psd.svg';
        case 'csv':
          return url + 'csv.svg';
        case 'mp3':
          return url + 'mp3.svg';
        case 'txt':
          return url + 'txt.svg';
        case 'png':
          return url + 'png.svg';
        case 'jpg':
        case 'jpeg':
          return url + 'jpg.svg';
        case 'pps':
        case 'ppsm':
        case 'ppsx':
        case 'pptm':
        case 'ppt':
        case 'pptx':
          return url + 'ppt.svg';
        case 'xls':
        case 'xlt':
        case 'xlsx':
        case 'xltm':
        case 'xltx':
          return url + 'xls.svg';
        case 'doc':
        case 'docx':
          return url + 'doc.svg';
        case 'pdf':
          return url + 'pdf.svg';
        default:
          return url + 'file.svg';
      }
    }
}

export default FileIconService