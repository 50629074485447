import HomeworkService from '../../../homework/services/HomeworkService'
import Config from '../../../core/common/services/ConfigurationService'

const AttachmentsService = {

  upload: (file: any, config: any, homework: any) => {
    let formData = new FormData()
    formData.append(file.type, file)
    return HomeworkService.upload(formData, config, homework)
  },
  getMaxFileSize () {
    let maxSize = Config.MaxFileSize.match(/\d+/).map(Number)[0]
    const oneMeg = 1048576
    const maxFileSize = maxSize * oneMeg
    return maxFileSize
  },
  fileSizeAllowed (file: any) {
    let maxFileSize = this.getMaxFileSize()
    if (file.size > maxFileSize) {
      return false
    }
    return true
  },
  getNoOfFiles () {
    return Number(Config.NumberOfFilesAllowed)
  },
  noOfFilesAllowed (existingAttachment: any, newAttachment: any) {
    let maxQuantiy = this.getNoOfFiles()
    if ((existingAttachment.length + newAttachment.length) > maxQuantiy) {
      return false
    }
    return true
  }
}

export default AttachmentsService