import React from 'react'
import {
  IonCardHeader,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonImg
} from '@ionic/react';

const HomeworkRemoved: React.FC = () => {
  return (
    <IonGrid>
      <IonRow>
        <IonCol size-md="10" sizeSm="12" offsetMd="1" offsetSm="0">
          <div className="homework-error-title headline-4">
          This Homework Has Been Removed
          </div>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol className="ion-text-center" size-md="10" offsetMd="1" >
          <div id="cardId" className="errorcard">
            <IonGrid>
              <IonRow >
                <IonCol size="12" >
                  <IonCardHeader>
                    <IonImg className="homework-error-desc" src="assets/homeworkRemoved.svg"></IonImg>
                  </IonCardHeader>
                  <IonCardContent className="errorpageContent headline-6">
           Your Teacher may have deleted this homework item.
                  </IonCardContent>
                </IonCol>

              </IonRow>
            </IonGrid>
          </div>
        </IonCol>
      </IonRow>
    </IonGrid>
  )
}

export default HomeworkRemoved
