
import React from 'react';
import { IonIcon } from '@ionic/react';
import { alert } from 'ionicons/icons';
import './FieldValidationError.css';

type Props = {
    error: string;
}

const FieldValidationError: React.FC<Props> = ({ error }) => {
  return (
    <div>
      <span title={error} className="field-validation-message">
        <span className="alert"> <IonIcon slot="start" icon={alert} mode="ios" /></span>
        <span className="message">{error}</span></span>
    </div>
  )
}

export default FieldValidationError;