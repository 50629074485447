import axios from 'axios'
import Config from './ConfigurationService'

export const HttpClient = (timeout = 30000) => {
  let axiosInstance = axios.create({
    baseURL: Config.HomeworkApiUrl,
    timeout: timeout,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  });

  axiosInstance.interceptors.request.use((request) => {
    return request; // need authotentication at later stages
  },
  (error) => {
    return Promise.reject(error)
  })

  axiosInstance.interceptors.response.use((response) => response, (error) => {
    if (error.response && (error.response.status === 401 || error.response.status === 403)) {
      console.log('unauthorized') // Look into routing at later states
    }
    return Promise.reject(error)
  })
  return axiosInstance;
}
