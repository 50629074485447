import React from 'react'
import './AttachmentInfo.css'
import FileHelper from '../../../fileIcons/services/FileHelper'
import FileIconService from '../../../fileIcons/services/FileIconService'
import { IonIcon, IonImg, IonButton } from '@ionic/react'
import { trash } from 'ionicons/icons'

interface AttachmentInfoProps {
    onDelete: (fileName: string) => void;
    attachment: any;
}

const AttachmentInfo: React.FC<AttachmentInfoProps> = ({ attachment, onDelete }) => {
  const getIconForExtension = (extension: string) => {
    let service = new FileIconService()
    return service.getIcon(extension)
  }

  return (
    <div className="attachment-info">
      <div className="attachement-icon-row">
        <div className="attachement-icon">
          {(attachment.uploadProgress < 100) &&
                        <div className="attachement-icon-percentage">
                          <div className="attachement-icon-percentage-overlay" />
                          <div className="attachement-icon-percentage-text">
                            <span>{attachment.uploadProgress}</span>%
                          </div>
                        </div>
          }
          <IonImg className="file-icons" src={getIconForExtension(attachment.extension)} />
        </div>
        <div className="attachment-info-form">
          <div className="info-width">
            <div className="attachment-info-file-size">{FileHelper.getHumanReadableFileSize(attachment.fileSize)}</div>
            <div title={attachment.fileName} className="attachment-title">{attachment.fileName} </div>
          </div>
          <div className="delete-div" >
            <IonButton slot="end" className="delete-button" color="light" disabled={attachment.uploadProgress < 100} onClick={() => onDelete(attachment.fileName)} >
              <IonIcon className="delete-icon" slot="start" icon={trash} mode="ios" />
              <span className="button-text" >Delete</span>
            </IonButton>
          </div>

        </div>
      </div>
    </div>

  )
}

export default AttachmentInfo
