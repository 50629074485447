import React, { useState } from 'react'
import Dropzone from 'react-dropzone'
import AttachmentInfo from '../AttachmentInfo/AttachmentInfo'
import FileHelper from '../../../fileIcons/services/FileHelper'
import { attach } from 'ionicons/icons'
import { IonButton, IonIcon } from '@ionic/react'
import LoDash from 'lodash'
import './Attachments.css'
import AttachmentsService from '../../services/AttachmentsService'
import HomeworkService from '../../../../homework/services/HomeworkService'
import { AttachmentStore } from '../../store/AttachmentStore'
import { Attachment } from '../../modal/Attachment'

interface AttachmentProps {
  onUploadingStatusChange: (isUploading: boolean) => void;
  onChange: (attachments: any) => void;
  onValidationErrorsChange: (validationerror: []) => void;
  homework: any;
}

const Attachments: React.FC<AttachmentProps> = ({ onUploadingStatusChange, onChange, onValidationErrorsChange, homework }) => {
  const [isUploading, setisUploading] = useState(false)
  const [maxfilesReached, setmaxfilesReached] = useState(false)
  const handleUploadingStatusChange = (isUploading: boolean) => {
    setisUploading(isUploading)
    onUploadingStatusChange(isUploading)
  }

  const getAttachmentList = () => {
    let reduxState = AttachmentStore.getState()
    return reduxState.attachments
  }
  const getValidationList = () => {
    let reduxState = AttachmentStore.getState()
    return reduxState.validations
  }

  let validationErrors = getValidationList()

  const attachments = getAttachmentList()

  const assignBlobReference = (data: any) => {
    let attachments = getAttachmentList()
    attachments.forEach((attachment: any) => {
      if (attachment.FileName === data.FileName) {
        attachment.Id = data.BlobReference
      }
    })
    updateAttachments(attachments)
  }

  const trackProgress = (thisAttachment: Attachment) => {
    let config = {
      onUploadProgress: (event: any) => {
        let attachments = getAttachmentList()
        attachments.forEach((attachment: any) => {
          if (attachment.FileName === thisAttachment.FileName) {
            attachment.UploadProgress = Math.round((event.loaded * 100) / event.total)
          }
        })
        let uploadInProgress = attachments.filter((a: any) => a.UploadProgress < 100)
        if (uploadInProgress.length === 0) { handleUploadingStatusChange(false) }

        updateAttachments(attachments)
      }
    }
    return config
  }

  const onDrop = async (files: any) => {
    let noOfFilesExceeded = false
    onAttachmentDrop()
    let attachments = getAttachmentList()
    let newAttachmentsToAdd = []
    if (!AttachmentsService.noOfFilesAllowed(attachments, files)) {
      onAttachmentUploadFailed(`Only five attachments can be attached of size (max) ${FileHelper.getHumanReadableFileSize(AttachmentsService.getMaxFileSize())} each`)
      noOfFilesExceeded = true
    }
    if (!noOfFilesExceeded) {
      for (let i = 0; i < files.length; i++) {
        let file = files[i]

        let fileExtension = FileHelper.getFileExtensionsFromFileName(file.name)
        if (!await HomeworkService.isFileExtensionValid(fileExtension)) {
          onAttachmentUploadFailed(`"${file.name}" is not supported`)
          continue
        }
        if (!AttachmentsService.fileSizeAllowed(file)) {
          onAttachmentUploadFailed(`Size of "${file.name}" must be less than ${FileHelper.getHumanReadableFileSize(AttachmentsService.getMaxFileSize())}`)
          continue
        }
        let filePresent = attachments.filter((x: Attachment) => x.FileName === file.name)
        if (filePresent.length === 0) {
          let attachment: Attachment = {
            Id: '',
            Title: '',
            Description: '',
            FileName: file.name,
            FileSize: file.size,

            UploadProgress: 0
          }

          handleUploadingStatusChange(true)
          newAttachmentsToAdd.push(attachment)

          AttachmentsService.upload(file, trackProgress(attachment), homework).then((result: any) => {
            assignBlobReference(result.data)
          }).catch(error => {
            onAttachmentUploadFailed(`Upload of "${attachment.FileName}" attachment failed`)
            removeAttachment(attachment.FileName)
            console.log(error)
          })
        }
      }
    }

    let allAttachments = LoDash.union(newAttachmentsToAdd, attachments)
    updateAttachments(allAttachments)
  }

  const removeAttachment = (fileName: string) => {
    let attachments = getAttachmentList()
    attachments.map((x: Attachment, i: number) => {
      if (x.FileName === fileName) {
        return attachments.splice(i, 1)
      }
      return attachments
    })
    if (attachments.length < AttachmentsService.getNoOfFiles()) { setmaxfilesReached(false) }
    updateAttachments(attachments)
    ValidationErrorsChange([])
  }

  const updateAttachments = (attachments: Attachment[]) => {
    if (attachments.length === AttachmentsService.getNoOfFiles()) { setmaxfilesReached(true) }
    onChange(attachments)
    getAttachmentList()
  }

  const onAttachmentUploadFailed = (message: string) => {
    let newValidationErrors = [message]
    ValidationErrorsChange(newValidationErrors)
  }

  const onAttachmentDrop = () => {
    if (validationErrors.length === 0) {
      return
    }

    validationErrors = []
    ValidationErrorsChange([])
  }

  const ValidationErrorsChange = (validationErrors: any) => {
    onValidationErrorsChange(validationErrors)
  }
  let dropZone: any
  return (
    <Dropzone ref={(el) => { dropZone = el }} noClick={true} onDrop={(files) => onDrop(files)}>
      {({ getRootProps, getInputProps }) => (
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <div className="attachment-container">
            {attachments.length === 0 &&
              <div className="attachment-container-text">Drag and Drop your files here
                <div>or</div>
              </div>
            }
            <div className="attachment-container-scroll-container">
              {attachments.map((attachment: Attachment) =>
                (
                  <AttachmentInfo key={attachment.FileName}
                    attachment={{
                      extension: FileHelper.getFileExtensionsFromFileName(attachment.FileName),
                      fileName: attachment.FileName,
                      name: attachment.Title || attachment.FileName.substring(0, attachment.FileName.lastIndexOf('.')),
                      id: attachment.Id,
                      description: attachment.Description,
                      fileSize: attachment.FileSize,
                      uploadProgress: attachment.UploadProgress || 100,
                      isUploading: isUploading
                    }}
                    onDelete={removeAttachment}
                  />
                ))
              }
            </div>
            {attachments.length > 0 &&
              <div className="attachment-container-text">Drag and Drop your files here
                <div>or</div>
              </div>
            }
            <IonButton slot="end" className="surface" color="light" onClick={() => dropZone.open()} disabled={isUploading || maxfilesReached}>
              <IonIcon className="attach-icon" slot="start" icon={attach} mode="ios" />
              <span className="button-text" >Choose a File</span>
            </IonButton>

          </div>
        </div>
      )}
    </Dropzone>
  )
}

export default Attachments