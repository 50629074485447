import React, { useState, useEffect } from 'react';
import {
  IonPage,
  IonHeader,
  IonContent,
  IonToolbar,
  IonLoading
} from '@ionic/react';
import HomeworkDescription from './HomeworkDescription'
import HomeworkService from '../../services/HomeworkService'
import { RouteComponentProps } from 'react-router';
import './HomeworkDetail.css'
import HomeworkNotFound from './HomeworkNotFound'
import HomeworkRemoved from './HomeworkRemoved'
import { appInsightsService } from '../../../core/common/services/AppInsightsService';
import GoogleAnalytics from '../../../core/googleanalytics/components/GoogleAnalytics';

interface HomeworkDetailProps extends RouteComponentProps<{
  schoolId: string;
  linkId: string;
  match: any // eslint-disable-next-line
}> { }

const HomeworkDetail: React.FC<HomeworkDetailProps> = ({ match }) => {
  const [showLoading, setShowLoading] = useState(true);
  const [homeworkDetail, setHomeworkDetail] = useState(undefined);
  const [isHomeworkRemoved, setHomeworkStatus] = useState(false);
  useEffect(() => {
    setHomeworkStatus(false)
    setHomeworkDetail(undefined)
    setShowLoading(true);
    HomeworkService.getHomeworkDetails(match.params.schoolId, match.params.linkId).then((homework: any) => {
      setHomeworkStatus(homework.isDeleted)
      setHomeworkDetail(homework)
      setShowLoading(false);
    })
  }, [match])

  if (showLoading) {
    return <IonLoading
      isOpen={showLoading}
      onDidDismiss={() => setShowLoading(false)}
      message={'Loading ...'}
      duration={5000}
    />
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="header" className="header" >
          <div className="headline-6 homework-header">Homework Detail </div>
        </IonToolbar>
      </IonHeader>
      <IonContent slot="fixed">
        <div className="homeworkDetail-page">
          <GoogleAnalytics pageTitle={'HomeworkDetail'} />
          {(() => {
            if (isHomeworkRemoved) {
              appInsightsService.trackEvent('HomeworkRemoved Page');
              return <HomeworkRemoved></HomeworkRemoved>
            } else if (homeworkDetail) {
              appInsightsService.trackEvent('HomeworkDetail Page');
              return <HomeworkDescription homeworkDetail={homeworkDetail} />
            } else {
              appInsightsService.trackEvent('HomeworkNotFound Page');
              return <HomeworkNotFound></HomeworkNotFound>
            }
          })()}

        </div>
      </IonContent>
    </IonPage>
  );
};

export default HomeworkDetail;
