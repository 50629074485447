import React, { useState } from 'react'
import ParseUrl from '../../../core/common/components/ParseUrl'
import {
  IonLabel,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonDatetime,
  IonItem
} from '@ionic/react';
import HomeworkAttachment from '../HomeworkAttachment/HomeworkAttachment';
import HomeworkSubmission from '../HomeworkSubmission/HomeworkSubmission';
import { toast, ToastContainer } from 'react-toastify';
import { css } from 'glamor';

type Props = {
  homeworkDetail: any
}

const HomeworkDescription: React.FC<Props> = ({ homeworkDetail }) => {
  let [homework, sethomework] = useState(homeworkDetail);
  let homeworkStatus = getStatus(homework);

  function getStatus (homework: any) {
    if (homework.handedInDate != null) {
      return homework.status
    } else if (homework.daycnt > 1) {
      return 'Due in ' + homework.daycnt + ' days'
    } else if (homework.daycnt >= 0) {
      return 'Due ' + (homework.daycnt === 1 ? 'Tommorow' : 'Today')
    } else {
      return 'Due date passed'
    }
  }

  function setReload (homework: any) {
    toast(
      <div className="toast-container">
        <span className="toast-body-icon">
          <IonIcon className="checkbox-usericon" src="assets/icons8-checkmark.svg"></IonIcon>
        </span>
        <span className="toast-body-text">
              Homework Submitted Successfully
        </span>
      </div>, {
        className: css({
          background: '#9AFFFD !important'
        }),
        bodyClassName: 'toast-body',
        autoClose: 5000,
        hideProgressBar: true,
        closeButton: false
      });
    sethomework(homework);
  }

  return (

    <div>

      {(() => {
        if (homework.handedInDate !== null) {
          return <div>
            <div className="sticky-header">
              <IonGrid>
                <IonRow >
                  <IonCol offsetMd="1">
                    <div className="notify-submit body-2">
                      {homework.studentname} submitted this homework on
                      <span className="dateFormatSm">
                        <IonDatetime readonly className="dateFormat" value={homework.handedInDate} displayFormat="DDDD D MMMM, YYYY"></IonDatetime>
                      </span>

                    </div></IonCol>
                </IonRow>
              </IonGrid>
            </div>
            <div className="header-divider"></div>
          </div>
        }
      })()}

      <IonGrid>
        <IonRow>
          <IonCol size-md="10" sizeSm="12" offsetMd="1" offsetSm="0">
            <div className="homework-title headline-4">
              {homework.title}
            </div>
            <div >
              <ul className="homework-info body-1">
                <li> <span> {homework.subject}</span></li>
                <span> Due
                  <IonDatetime readonly className="dateFormat" value={homework.dueDate} displayFormat="DDDD D MMMM, YYYY"></IonDatetime>
                </span>
              </ul>
            </div>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size-md="10" offsetMd="1" >
            <div id="cardId" className="homeworkdetailcard">
              <IonGrid>
                <IonRow>
                  <IonCol className="hide-largescreen" size-md="6" size-xs="12" size-sm="12" >
                    <IonCardContent>
                      <div className="homework-status headline-5">{homeworkStatus}</div>
                    </IonCardContent>
                  </IonCol>
                  <IonCol size-xl="8" size-md="12" size-xs="12">
                    <IonCardHeader>
                      <IonCardTitle className="homework-desc-title headline-6">
                        <IonLabel >Homework Description</IonLabel>
                      </IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent>
                      <IonItem className="homework-divider">
                        <div className="homework-description body-1">
                          <ParseUrl description={homework.description}></ParseUrl>
                        </div>
                      </IonItem>
                      <div className="homework-setby">
                        <div>
                          <ul>
                            <li>
                              <IonIcon className="setby-usericon" src="assets/user.svg"></IonIcon>
                              <span className="">Set by {homework.setBy}</span>
                            </li>
                            <span><IonDatetime readonly className="dateFormat" value={homework.startDate} displayFormat="DDDD D MMMM, YYYY"></IonDatetime></span>
                          </ul>
                          <IonCol className="hide-largescreen" size-md="6" size-xs="6" size-sm="6" >
                            <div className="attachment-section submit-section">
                              <div className="attachment-heading">Attachments</div>
                              <HomeworkAttachment attachments={homework.attachments} isStudentAttachment={false}></HomeworkAttachment>
                            </div>
                          </IonCol>
                          {
                            homework.handedInDate !== null &&
                            <div className="submit-section">
                              <div className="homework-submit-title headline-6">
                                <div>Homework Submitted for {homework.studentname}</div>
                                <HomeworkAttachment attachments={homework.studentAttachments} isStudentAttachment={true}></HomeworkAttachment>
                              </div>
                            </div>
                          }
                          {
                            (homework.handedInDate === null && homeworkStatus !== 'Due date passed') &&
                            <div className="submit-section">
                              {(homework.allowStudentAttachments === false) &&
                                <div className="in-person">This Homework needs to be handed in person</div>
                              }
                              {homework.allowStudentAttachments === true &&
                                <HomeworkSubmission onReload={setReload} homework={homework} ></HomeworkSubmission>}
                            </div>
                          }
                        </div>

                      </div>
                    </IonCardContent>
                  </IonCol>
                  <IonCol className="hide-smallscreen" offsetXl="1" offsetMd="0" size-xl="3" >
                    <IonCardContent>
                      <div className="homework-status headline-5">{homeworkStatus}</div>
                    </IonCardContent>
                    <IonCardContent>
                      <div className="attachment-section">
                        <div className="attachment-heading">Attachments</div>
                        <HomeworkAttachment attachments={homework.attachments} isStudentAttachment={false} ></HomeworkAttachment>
                      </div>
                    </IonCardContent>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>
      <ToastContainer
        autoClose={2000}
        position="bottom-center"
      />
    </div>
  )
}

export default HomeworkDescription
