const FileHelper = {
  getHumanReadableFileSize: (size: number) => {
    if (size === 0) {
      return '0 Bytes';
    }

    var i = Math.floor(Math.log(size) / Math.log(1024));
    return (size / Math.pow(1024, i) as any).toFixed(2) * 1 + ' ' + ['Bytes', 'KB', 'MB', 'GB', 'TB'][i];
  },
  getFileExtensionsFromFileName: (filename: string) => {
    let lastIndex = filename.lastIndexOf('.');
    if (lastIndex > -1 && lastIndex < (filename.length - 1)) {
      return filename.substring(filename.lastIndexOf('.') + 1);
    }
    return '';
  }

}

export default FileHelper