import config from '../../common/services/ConfigurationService';
import { useEffect } from 'react';
import ReactGA from 'react-ga';

type Props = {
  pageTitle : string
}

const GoogleAnalytics : React.FC<Props> = ({ pageTitle }) => {
  useEffect(() => {
    ReactGA.initialize(config.GoogleAnalyticsTrackingId);
    ReactGA.pageview(pageTitle);
  });
  return (null);
}

export default GoogleAnalytics