import React from 'react'
import {
  IonImg
} from '@ionic/react';
import './HomeworkAttachment.css'
import HomeworkService from '../../services/HomeworkService';

type Props = {
    attachments: [],
    isStudentAttachment:boolean
}

const HomeworkAttachment: React.FC<Props> = ({ attachments, isStudentAttachment }) => {
  const downloadAttachment = async (file: any) => {
    if (!isStudentAttachment) {
      HomeworkService.downloadAttachment(file.schoolId, file.id);
    }
  }
  return (
    <div>
      {attachments.length === 0 &&
                <div className="attachment-none">
                    None
                </div>
      }
      {attachments.map((file: any) => (
        <div key={file.id}>
          <div className={!isStudentAttachment ? 'button-link' : 'aa'} onClick={() => downloadAttachment(file)}>
            <div className="attachment-item">
              <div className="attachment-icon" >
                <IonImg className="file-icons" src={file.icon} />
              </div>
              <div>
                <div className="file-name" title={file.fileName}>{file.fileName}</div>
                <div className="file-size">{file.fileSize}</div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default HomeworkAttachment
