import React from 'react'

type Props = {
  description: any,
}

const ParseUrl: React.FC<Props> = ({ description }) => {
  var textPart, linkPart, descriptionWithLink;
  var arrayForLink, arrayOfHtmlText;
  var htmlText: any[] = [];
  var url = /\[([^|\]]*?)\|([^\]]*?)]/gim;

  function cleanseHttpAddress (httpAddress: string) {
    return httpAddress.toUpperCase().lastIndexOf('HTTP://', 0) === 0 || httpAddress.toUpperCase().lastIndexOf('HTTPS://', 0) === 0 ? httpAddress : 'http://' + httpAddress;
  }

  var replacerWithLink = function (match: string, p1: string, p2: string) {
    if (p1.trim().length === 0) {
      p1 = p2;
    }
    return '<CustomHyperLink><LinkTag_' + cleanseHttpAddress(p2.trim()) + '_' + p1.trim() + '_/><CustomHyperLink>';
  };

  descriptionWithLink = description.replace(url, replacerWithLink);
  arrayOfHtmlText = descriptionWithLink.split('<CustomHyperLink>');
  getHtmlText(arrayOfHtmlText);

  function getHtmlText (splitString: any) {
    for (let counter in splitString) {
      if (splitString[counter].includes('<LinkTag')) {
        arrayForLink = splitString[counter].split('_');
        linkPart = <a target="_blank" href={arrayForLink[1].trim()} rel="noopener noreferrer">{arrayForLink[2].trim()}</a>;
        htmlText.push(linkPart);
      } else {
        textPart = splitString[counter];
        htmlText.push(textPart);
      }
    }
  }

  return (
    <span>
      {htmlText.map(htmlText => <span>{htmlText}</span>)}
    </span>
  )
}

export default ParseUrl;