import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import config from './ConfigurationService';

class AppInsightsService {
    appInsights: ApplicationInsights;
    constructor () {
      this.appInsights = new ApplicationInsights({
        config: {
          instrumentationKey: config.AppInsightsInstrumentationKey
        }
      });
      this.appInsights.loadAppInsights();
      this.appInsights.trackPageView();
    }

    /**
     * trackEvent
     */
    public trackEvent (eventName: string) {
      this.appInsights.trackEvent({ 'name': eventName });
    }
}

export let appInsightsService = new AppInsightsService()
