import { createStore } from 'redux';

var initialState = {
  attachments: [],
  validations: []
}

const ActionTypes = {
  'GET_Attachments': 'GET_Attachments',
  'GET_Validations': 'GET_Validations'
}

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case ActionTypes.GET_Attachments:
      return {
        ...state,
        attachments: action.attachments
      };
    case ActionTypes.GET_Validations:
      return {
        ...state,
        validations: action.validations
      }
    default:
      return state;
  }
}
let AttachmentStore = createStore(reducer, initialState);

export { AttachmentStore, ActionTypes }
